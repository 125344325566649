import validate from "/home/forge/deployments/entradas/live/2025-01-22_14-56-09_9b4cb4b/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.10.7_eslint@9.18.0_typescript@5.5.4_vite@6.0.11/node_modules/nuxt/dist/pages/runtime/validate.js";
import cms_45global from "/home/forge/deployments/entradas/live/2025-01-22_14-56-09_9b4cb4b/layers/cms/middleware/cms.global.ts";
import auth_45token_45global from "/home/forge/deployments/entradas/live/2025-01-22_14-56-09_9b4cb4b/layers/account/middleware/auth-token.global.ts";
import backend_45token_45global from "/home/forge/deployments/entradas/live/2025-01-22_14-56-09_9b4cb4b/layers/account/middleware/backend-token.global.ts";
import affiliate_45and_45referrer_45global from "/home/forge/deployments/entradas/live/2025-01-22_14-56-09_9b4cb4b/layers/base/middleware/affiliate-and-referrer.global.ts";
import consent_45global from "/home/forge/deployments/entradas/live/2025-01-22_14-56-09_9b4cb4b/layers/base/middleware/consent.global.ts";
import utm_45global from "/home/forge/deployments/entradas/live/2025-01-22_14-56-09_9b4cb4b/layers/base/middleware/utm.global.ts";
import widget_45mode_45global from "/home/forge/deployments/entradas/live/2025-01-22_14-56-09_9b4cb4b/layers/base/middleware/widget-mode.global.ts";
import redirects_45global from "/home/forge/deployments/entradas/live/2025-01-22_14-56-09_9b4cb4b/apps/entradas/middleware/redirects.global.ts";
import manifest_45route_45rule from "/home/forge/deployments/entradas/live/2025-01-22_14-56-09_9b4cb4b/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.10.7_eslint@9.18.0_typescript@5.5.4_vite@6.0.11/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  cms_45global,
  auth_45token_45global,
  backend_45token_45global,
  affiliate_45and_45referrer_45global,
  consent_45global,
  utm_45global,
  widget_45mode_45global,
  redirects_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/home/forge/deployments/entradas/live/2025-01-22_14-56-09_9b4cb4b/layers/builder/middleware/admin.ts")
}