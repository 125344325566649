<template>
  <nav
    class="bg-footer bleed-footer bleed-bg skin:bleed-none flex flex-col py-4"
  >
    <PageContainer
      id="footer"
      as="footer"
      :default-theme="false"
      :default-vertical-spacing="false"
      :grow="false"
      class="grid w-full grid-cols-2 grid-rows-[auto,auto] items-center gap-2 lg:grid-cols-[1fr,200px]"
    >
      <div class="col-span-2 -mb-2 text-center lg:col-span-1 lg:text-left">
        <NuxtLink
          v-for="{ label, url } in config?.footerMenu"
          :key="label"
          class="text-on-footer hover:text-secondary mb-2 mr-4 inline-block"
          :to="url"
          :target="
            url.startsWith('http') || url.startsWith('www')
              ? LINK_TARGET.BLANK
              : LINK_TARGET.SELF
          "
        >
          {{ label }}
        </NuxtLink>

        <button
          class="text-on-footer hover:text-secondary"
          @click="toggleModal({ settings: true, initial: false })"
          v-text="t('consent')"
        />
      </div>
      <div
        class="col-span-2 mt-6 flex w-full justify-center space-x-3 lg:col-span-1 lg:mt-0"
      >
        <SocialLink
          v-for="socialLink in config?.socialLinks"
          :key="socialLink"
          :link="socialLink"
          class="text-on-footer dark:text-primary transition-opacity duration-150 hover:opacity-80"
        />
      </div>
    </PageContainer>

    <slot />
  </nav>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { config } = useCms()
const { toggleModal } = useConsentManager()

defineOptions({
  name: 'PageFooterContent',
})
</script>

<i18n>
de:
  consent: "Cookie Einstellungen"
es:
  consent: "Configuración de cookies"
</i18n>
