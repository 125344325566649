<template>
  <div class="flex flex-col space-y-4">
    <div
      v-for="(
        { price, taxName, taxRate, taxDescription, reference }, index
      ) in order.priceCompositions"
      :key="index"
      class="mt-1 flex flex-col space-y-4 text-right"
    >
      <div class="mt-1 flex">
        <div class="flex flex-grow flex-col text-right">
          <span
            v-text="
              `${n(netPrice, 'currency')} + ${n(taxPrice, 'currency')} (${taxName} ${taxRate}%)`
            "
          />
          <span class="text-sm" v-html="taxDescription" />
          <span class="text-sm font-bold" v-text="reference" />
        </div>

        <span class="px-1.5">=</span>
        <span v-text="n(price.total, 'currency')" />
      </div>

      <div
        v-if="order?.priceCompositions?.length > 1 && index === 0"
        class="hidden print:flex print:items-center"
      >
        <div class="text-2xl">✁</div>
        <div class="border-text flex-grow border-b-2 border-dashed"></div>
      </div>
    </div>

    <div class="flex flex-col space-y-0 text-right">
      <div class="text-right">
        <span class="font-bold" v-text="`Total: `" />
        <span
          v-text="n(getTotalPriceFromOrder(order), 'currency')"
          class="font-bold"
        />
      </div>
      <span class="text-right text-xs">(Todo incluido)</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Order } from '#gql/default'

interface Props {
  order: Order
  showPresaleDisclaimer?: boolean
}

const { order, showPresaleDisclaimer = true } = defineProps<Props>()

const { n } = useI18n()

const netPrice = computed(() => {
  return (
    order.priceCompositions?.reduce(
      (acc, curr) => acc + (curr?.price?.net ?? 0),
      0
    ) ??
    order.price.net ??
    0
  )
})

const taxPrice = computed(() => {
  return (
    order.priceCompositions?.reduce(
      (acc, curr) => acc + (curr?.price?.tax ?? 0),
      0
    ) ??
    order.price.tax ??
    0
  )
})

defineOptions({
  name: 'OrderConfirmationOrderOverviewPrice',
})
</script>
