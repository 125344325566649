<template>
  <button
    class="button button-sm bg-secondary text-on-secondary hover:bg-secondary-dark-1 border-secondary hover:border-secondary-dark-1 w-auto border print:hidden"
    @click="print"
  >
    <InlineSvg :name="SVG_ICON.PRINTER" />
    <span v-text="t('print')" />
  </button>
</template>

<script setup lang="ts">
import { SVG_ICON } from '@assets/constants/inline-svg'

const { t } = useI18n()

function print() {
  window.print()
}

defineOptions({
  name: 'PrintButton',
})
</script>

<i18n>
de:
  print: "Drucken"
es:
  print: "Imprimir"
</i18n>
