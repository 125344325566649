<template>
  <div class="relative animate-pulse">
    <div
      class="mr-2 mt-2 h-[42px] w-24 rounded-full"
      :class="[cssClasses.color]"
    />
  </div>
</template>

<script lang="ts" setup>
const { cssClasses } = useSkeleton()

defineOptions({
  name: 'FiltersSkeleton',
})
</script>
