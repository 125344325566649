<template>
  <OrderConfirmationOrderOverviewWrapper
    :order
    class="print:text-black print:dark:text-black"
  >
    <div class="grid grid-cols-3 py-2">
      <span class="font-bold" v-text="`${t('purchaseDate')}:`" />
      <span class="col-span-2" v-text="formattedCreatedAt" />

      <span class="font-bold" v-text="`${t('cinema')}:`" />
      <div class="col-span-2">
        <span
          class="text-primary font-bold dark:font-normal print:text-black print:dark:text-black"
          v-text="`${order.cinema?.name}, `"
        />
        <span v-text="getFormattedCinemaAddress(order.cinema!)" />
      </div>

      <span class="font-bold" v-text="`${t('show')}:`" />
      <span
        class="text-primary col-span-2 font-bold dark:font-normal print:text-black print:dark:text-black"
        v-text="showContentRatingString"
      />

      <span class="font-bold" v-text="`${t('dateTime')}:`" />
      <span
        class="text-primary col-span-2 font-bold dark:font-normal print:text-black print:dark:text-black"
        v-text="dateTimeString"
      />

      <span class="font-bold" v-text="`${t('auditorium')}:`" />
      <span
        class="text-primary col-span-2 font-bold dark:font-normal print:text-black print:dark:text-black"
        v-text="order.show!.auditorium!.name"
      />

      <span class="font-bold" v-text="`${t('seats')}:`" />
      <span class="col-span-2" v-text="order.overview.seats" />

      <span class="font-bold" v-text="`${t('seatCategory')}:`" />
      <span class="col-span-2" v-text="order.overview.seatCategories" />

      <span class="font-bold" v-text="`${t('orderNumber')}:`" />
      <span class="col-span-2" v-text="purchaseData.id" />

      <span
        v-if="order.collectionCode"
        class="font-bold"
        v-text="`${t('collectionCode')}:`"
      />
      <span
        v-if="order.collectionCode"
        class="col-span-2"
        v-text="order.collectionCode"
      />
    </div>
  </OrderConfirmationOrderOverviewWrapper>
</template>

<script setup lang="ts">
import type { PurchaseData } from '@account/types/order'
import type { Order } from '#gql/default'
import { getFormattedCinemaAddress } from '@base/utils/format-cinema-address'

interface Props {
  order: Order
  purchaseData: PurchaseData
}

const { order, purchaseData } = defineProps<Props>()

const { t, locale } = useI18n()

const formattedCreatedAt = computed(() => {
  // TODO: We need to check portal formatting
  const date = new Date(order.createdAt)
  return date.toLocaleString(locale.value, {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })
})

const showContentRatingString = computed(() => {
  let temp = order.show?.name

  // TODO: Need to check is we use FSK or something else
  if (order.show?.movie?.contentRating?.minimumAge) {
    temp += ` | FSK ${order.show.movie.contentRating.minimumAge}`
  }

  return temp
})

const dateTimeString = computed(() => {
  const date = new Date(order.show!.beginning)
  return `${date.toLocaleDateString(locale.value, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })} ${date.toLocaleTimeString(locale.value, {
    hour: '2-digit',
    minute: '2-digit',
  })}`
})

defineOptions({
  name: 'OrderConfirmationOrderOverviewTicket',
})
</script>

<i18n>
de:
  row: "Reihe"
  seat: "Sitz"
  cinema: "Kino"
  show: "Vorstellung"
  dateTime: "Datum, Uhrzeit"
  seats: "Sitzplätze"
  seatCategory: "Sitzkategorie"
  orderNumber: "Bestellnummer"
  auditorium: "Saal"
  purchaseDate: "Datum der Bestellung"
  collectionCode: "Abholcode"
es:
  row: "Fila"
  seat: "Asiento"
  cinema: "Cine"
  show: "Película"
  dateTime: "Fecha/Hora"
  seats: "Butacas"
  seatCategory: "Categoría"
  orderNumber: "Localizador"
  auditorium: "Sala"
  purchaseDate: "Fecha de compra"
  collectionCode: "Código de barras"
</i18n>
