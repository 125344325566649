import getUtmParams from '@base/utils/get-utm-params'

/**
 * Global redirects middleware that handles various URL patterns and legacy routes
 *
 * Handles the following redirects:
 * 1. Cinema index redirects: /cine/{city}/{cinema}
 *    → /cine/{city}/{cinema}/sesiones
 */

/**
 * Helper to add UTM parameters to URL
 * Filters out undefined values and constructs proper query string
 */
function addUtmToPath(
  path: string,
  urlParams: Record<string, string | undefined>
): string {
  if (Object.keys(urlParams).length === 0) {
    return path
  }
  const definedParams = Object.fromEntries(
    Object.entries(urlParams).filter(([_, value]) => value !== undefined)
  ) as Record<string, string>

  const params = new URLSearchParams(definedParams)
  return `${path}?${params.toString()}`
}

export default defineNuxtRouteMiddleware((to) => {
  // Collect UTM parameters from URL and state
  const urlUtmParams = getUtmParams(
    to.query as Record<string, string | undefined>
  )
  const utmState = useUtmState()
  const utmParams = { ...utmState.value, ...urlUtmParams }

  /**
   * Handle cinema index redirects
   * Redirects cinema base URLs to their sessions page
   * Example: /cine/san-sebastian-de-los-reyes/yelmo-cines-plaza-norte-2
   * →        /cine/san-sebastian-de-los-reyes/yelmo-cines-plaza-norte-2/sesiones
   */
  if (
    to.path.match(/^\/cine\/[^/]+\/[^/]+\/?$/) && // Matches /cine/{citySlug}/{cinemaSlug} with optional trailing slash
    !to.path.endsWith('/sesiones')
  ) {
    const newPath = to.path.replace(/\/?$/, '/sesiones')
    return navigateTo(addUtmToPath(newPath, utmParams), {
      redirectCode: 302,
      external: false,
    })
  }
})
