<template>
  <div class="contents">
    <CinemaBanner :cinema page="SHOWS" class="mb-8" />

    <div
      v-if="cinema.shortFaq"
      class="bg-neutral-dark-2 mb-8 w-full rounded-md border px-8 py-4 shadow-lg"
    >
      <div
        class="prose prose-p:my-1 prose-td:py-1.5 dark:prose-invert prose-hr:my-4 prose-strong:text-inherit prose-tr:border-neutral-light-1 dark:prose-tr:border-neutral-dark-1 max-w-none"
        v-html="cinema.shortFaq"
      />
    </div>

    <CatalogProgram
      :filters
      :layout
      :items-per-page="5"
      :cinema-ids="[cinema.id]"
      :fetch-fallback-results="true"
      :link-params="[]"
      :preserve-filters="false"
      :booking-modal="false"
      :render-above-the-fold="false"
      :enable-pagination="true"
      :enable-pagination-on-scroll="true"
    />

    <LazyExpressBooking
      v-if="
        !isWidgetMode && cinema?.bookingType === CINEMA_BOOKING_TYPE.FIRST_PARTY
      "
      :cinema
    />
  </div>
</template>

<script setup lang="ts">
import type { Cinema } from '#gql/default'

interface Props {
  cinema: Cinema
}

const { cinema } = defineProps<Props>()
const { isWidgetMode } = useWidgetMode()
const { t } = useI18n()

const appConfig = useAppConfig()
const filters = computed(
  () => (appConfig.filtersForCinemaProgramPage as UrlFilter[]) ?? []
)

const layout = computed(() =>
  cinema.isDriveIn || cinema.isOpenAir
    ? PROGRAM_LAYOUT.GROUPED_BY_DAY
    : PROGRAM_LAYOUT.GROUPED_BY_MOVIE
)

usePageMetas({
  title: `${cinema.name} | ${t('tickets')}`,
  description: t('description', {
    cinemaName: cinema.name,
    cityName: cinema.city.name,
  }),
  ...cinema.meta,
})

defineI18nRoute({
  paths: {
    en: '/cinema/[citySlug]/[cinemaSlug]/program',
    de: '/kino/[citySlug]/[cinemaSlug]/vorstellungen',
    es: '/cine/[citySlug]/[cinemaSlug]/sesiones',
  },
})

const { $gtm } = useNuxtApp()

onMounted(() => {
  $gtm.trackPageView()
})

defineOptions({
  name: 'PageCinemaCitySlugCinemaSlugProgram',
})
</script>

<i18n>
de:
  tickets: "Kinotickets"
  description: "Jetzt bei kinoheld Kinotickets für das Kino {cinemaName} schnell und bequem online kaufen."
es:
  tickets: "Cartelera y horarios"
  description: "Explora la cartelera de {cinemaName}. Descubre las últimas películas en proyección, horarios, precios y más. ¡Compra todas las entradas de {cinemaName} en {cityName}!"
</i18n>
