<template>
  <CinemaInfo :cinema="cinema" />
  <LazyExpressBooking
    v-if="
      !isWidgetMode && cinema?.bookingType === CINEMA_BOOKING_TYPE.FIRST_PARTY
    "
    :cinema
  />
</template>

<script setup lang="ts">
import type { Cinema } from '#gql/default'

interface Props {
  cinema: Cinema
}

defineProps<Props>()

const { isWidgetMode } = useWidgetMode()

defineOptions({
  name: 'GenericPageCinemaInfo',
})
</script>
