import type { RouteLocation } from 'vue-router'
import type { LinkParam } from '#gql/default'

export default function useGetMovieLink() {
  interface Payload {
    movieSlug: string
    linkParams?: LinkParam[]
  }

  const { locale } = useI18n()
  const localeRoute = useLocaleRoute()

  function getMovieLink(payload: Payload) {
    const { movieSlug, linkParams = [] } = payload

    return localeRoute(
      {
        name: ROUTE_NAMES.MOVIE_PAGE,
        params: {
          movieSlug,
        },
        query: {
          ...linkParams.reduce(
            (acc, { key, value }) => {
              acc[key] = value
              return acc
            },
            {} as Record<string, string>
          ),
        },
      },
      locale.value
    ) as RouteLocation
  }

  return {
    getMovieLink,
  }
}
